<template>
  <div>

    <!-- filter -->
    <div class="row justify-content-end align-items-center">

      <!-- filter by name -->
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input type="text" v-model="filter.name" placeholder="Cari Berdasar Nama"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>

    </div>

    <!-- table -->
    <b-table striped hover responsive class="mt-3" :items="items" :fields="fields">

      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'actions' ? '15%' : '' }" />
      </template>

      <template #cell(duration)="data">
        {{ data.item.start_date + " s/d " + data.item.end_date }}
      </template>

      <template #cell(amount)="data">
        <span v-if="data.item.is_percent == 0">Rp. {{ parseInt(data.item.amount).toLocaleString("id-ID") }}</span>
        <span v-if="data.item.is_percent == 1">{{ parseInt(data.item.amount) }}%</span>
        <div v-if="data.item.max_amount > 0">Maks : Rp.{{ parseInt(data.item.max_amount).toLocaleString('id-ID') }}</div>
      </template>

      <template #cell(status)="data">
        <b-badge pill variant="success" class="mx-1" v-if="data.item.is_expired == 0">
          Aktif</b-badge>
        <b-badge pill variant="danger" class="mx-1" v-if="data.item.is_expired == 1">
          Expired</b-badge>
      </template>

      <template #cell(actions)='data'>
        <!-- for modal -->
        <template v-if="isModal">
          <b-button size="sm" class="mr-1 btn-info" :disabled="data.item.is_expired == 1"
            @click="chooseCoupon(data.item)"> Pilih </b-button>
        </template>
        <!-- for master -->
        <template v-if="!isModal">
        </template>
      </template>

    </b-table>

    <!-- pagination -->
    <b-pagination v-if="items.length != 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
      @page-click="pageOnClick" class="mt-4">
    </b-pagination>

  </div>
</template>

<script>

import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js"

export default {

  props: {
    isModal: Boolean
  },

  data() {
    return {
      // filter
      filter: {
        name: ''
      },
      // table head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "duration",
          label: "Durasi",
          sortable: true,
        },
        {
          key: "amount",
          label: "Besaran",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // table items
      items: [],
      // pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 1,
    }
  },

  methods: {

    async pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      await this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate(
        "coupons",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      )
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
    },

    filterByName() {
      debounce(() => {
        if (this.filter.name == "") {
          this.currentPage = this.lastCurrentPage
        } else {
          this.currentPage = 1
        }
        this.pagination()
      }, 500)
    },

    chooseCoupon(value) {
      this.$emit('chooseCoupon', value)
    }

  },

  mounted() {
    // get data
    this.pagination()
  }

}

</script>

<style lang="scss" scoped></style>