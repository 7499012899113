<template>
    <div>
  
      <b-modal id="modal-coupon" size="xl" title="Pilih Kupon" hide-footer>
        <Table :isModal="true" @chooseCoupon="chosenCoupon" />
      </b-modal>
  
    </div>
  </template>
  
  <script>
  
  // components
  import Table from '@/component/masters/coupons/Table'
  
  export default {
  
    components: {
      Table
    },
  
    methods: {
  
      chosenCoupon(value) {
        this.$emit("chosenCoupon", value)
      }
  
    }
  
  }
  </script>
  
  <style lang="scss" scoped></style>
  