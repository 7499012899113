import module from '@/core/modules/CrudModule.js'
import { getPromoAmount } from "@/core/modules/Helper.js"
import api from '@/core/services/api.service.js'
api.setHeader()

const PaymentModule = {  

  async getDoctors(patientId) {
    let route, result = []
    if (typeof patientId !== 'undefined' && patientId != '') {
      route = `doctors/get-by-patient/${patientId}`
    } else {
      route = 'doctors'
    }
    let response = await module.setDoctorTreeSelect(route)
    if (response.state == 'success') {
      result = response.data
      result.unshift({ label: 'Pilih Dokter', id: '', isDisabled: true })
    }
    return result
  },

  async getPaymentCategories() {
    let result = []
    let response = await api.get('payment-categories')
    response.data.data.forEach(value => {
      result.push({
        label: value.name,
        id: value.id,
        price: value.price,
        const_price: value.price,
        discount_percentage: 0,
        discount_amount: 0,
        discount_type: 0,
        is_bundle: value.isBundle,
        is_deposit: 1,
        quantity: 1,
        promo_amount: getPromoAmount(value.promo, value.const_price),
        promo: value.promo
      })
    })
    return result
  },

  async getPaymentCategoryById(id) {
    let response = await api.get(`payment-categories/${id}`)
    let value = response.data.data
    return {
      label: value.name,
      id: value.id,
      price: value.price,
      const_price: value.price,
      discount_percentage: 0,
      discount_amount: 0,
      discount_type: 0,
      is_bundle: value.isBundle,
      is_deposit: 1,
      quantity: 1,
      promo_amount: getPromoAmount(value.promo, value.const_price),
      promo: value.promo
    }
  },

  async getMedicines() {
    let result = []
    let response = await api.get('medicine-no-paginations')
    response.data.data.forEach(value => {
      result.push({
        label: value.name,
        id: value.id,
        price: value.sales_price,
        stock: value.stock,
        quantity: 1,
        promo_amount: getPromoAmount(value.promo, value.const_price),
        promo: value.promo
      })
    })
    return result
  },

  async getMedicineById(id) {
    let response = await api.get(`medicines/${id}`)
    let value = response.data.data
    return {
      label: value.name,
      id: value.id,
      price: value.sales_price,
      stock: value.stock,
      quantity: 1,
      promo_amount: getPromoAmount(value.promo, value.const_price),
      promo: value.promo
    }
  },

  async getLabProcedures() {
    let result = []
    let response = await api.get('lab-procedures', `?with_promo=true`)
    response.data.data.forEach(value => {
      result.push({
        label: value.name,
        id: value.id,
        price: value.price,
        const_price: value.price,
        discount_percentage: 0,
        discount_amount: 0,
        discount_type: 0,
        promo_amount: getPromoAmount(value.promo, value.const_price),
        promo: value.promo
      })
    })
    return result
  },

}

export default PaymentModule